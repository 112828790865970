<template>
    <v-card class="pb-2">
        <v-toolbar dense color="secondary" dark>
            <v-toolbar-title
                :class="{ 'body-2': $vuetify.breakpoint.smAndDown }"
                >{{ property.name }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <span
                class="font-weight-light hidden-sm-and-down"
                :class="[
                    { subheading: $vuetify.breakpoint.smAndUp },
                    { caption: $vuetify.breakpoint.smAndDown },
                ]"
                >{{ company.name }}</span
            >
        </v-toolbar>
        <p
            class="mt-2 mb-1 mx-3"
            :class="{ 'body-2': $vuetify.breakpoint.smAndDown }"
        >
            {{ property.address }} <br />
            {{ property.city }}, {{ property.state }} {{ property.zip }}
        </p>
        <p
            class="mt-1 mx-3"
            :class="{ 'body-2': $vuetify.breakpoint.smAndDown }"
            style="margin-bottom: 6px"
        >
            <strong>{{ $t("applications.phone") }}:</strong>
            {{ property.phone }} |
            <strong>{{ $t("applications.fax") }}:</strong>
            {{ property.fax }} |
            <strong>{{ $t("applications.email") }}: </strong>
            <a :href="`mailto:${email}`">{{ email }}</a>
        </p>
        <p
            class="mt-3 mb-0 mx-3"
            v-html="
                $t('applications.thank-you-for-your-interest-in', {
                    property: `<strong>${property.name}</strong>`,
                })
            "
        ></p>
    </v-card>
</template>
<script>
export default {
    name: "property-info",
    components: {},
    props: {
        property: {
            type: Object,
            required: true,
        },
        company: {
            type: Object,
            required: true,
        },
        email: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>