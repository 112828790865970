var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pb-2" },
    [
      _c(
        "v-toolbar",
        { attrs: { dense: "", color: "secondary", dark: "" } },
        [
          _c(
            "v-toolbar-title",
            { class: { "body-2": _vm.$vuetify.breakpoint.smAndDown } },
            [_vm._v(_vm._s(_vm.property.name))]
          ),
          _c("v-spacer"),
          _c(
            "span",
            {
              staticClass: "font-weight-light hidden-sm-and-down",
              class: [
                { subheading: _vm.$vuetify.breakpoint.smAndUp },
                { caption: _vm.$vuetify.breakpoint.smAndDown }
              ]
            },
            [_vm._v(_vm._s(_vm.company.name))]
          )
        ],
        1
      ),
      _c(
        "p",
        {
          staticClass: "mt-2 mb-1 mx-3",
          class: { "body-2": _vm.$vuetify.breakpoint.smAndDown }
        },
        [
          _vm._v(" " + _vm._s(_vm.property.address) + " "),
          _c("br"),
          _vm._v(
            " " +
              _vm._s(_vm.property.city) +
              ", " +
              _vm._s(_vm.property.state) +
              " " +
              _vm._s(_vm.property.zip) +
              " "
          )
        ]
      ),
      _c(
        "p",
        {
          staticClass: "mt-1 mx-3",
          class: { "body-2": _vm.$vuetify.breakpoint.smAndDown },
          staticStyle: { "margin-bottom": "6px" }
        },
        [
          _c("strong", [_vm._v(_vm._s(_vm.$t("applications.phone")) + ":")]),
          _vm._v(" " + _vm._s(_vm.property.phone) + " | "),
          _c("strong", [_vm._v(_vm._s(_vm.$t("applications.fax")) + ":")]),
          _vm._v(" " + _vm._s(_vm.property.fax) + " | "),
          _c("strong", [_vm._v(_vm._s(_vm.$t("applications.email")) + ": ")]),
          _c("a", { attrs: { href: "mailto:" + _vm.email } }, [
            _vm._v(_vm._s(_vm.email))
          ])
        ]
      ),
      _c("p", {
        staticClass: "mt-3 mb-0 mx-3",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("applications.thank-you-for-your-interest-in", {
              property: "<strong>" + _vm.property.name + "</strong>"
            })
          )
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }